<script setup>
import '~/assets/scss/common.scss'

const route = useRoute()
const router = useRouter()
const error = useError()
const { t } = useI18n()

const routePath = computed(() => route.path.split('/'))

useSeoMeta({
    title: () => `Error 404 — ${t('service.name')}`,
})
</script>

<template>
    <div>
        <div class="error">
            <div class="error__logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="100px" viewBox="0 0 21 24" fill="none">
                    <path d="M7.8937 1.4306C6.51946 2.22325 5.3938 2.87989 5.3938 2.89396C5.3938 2.9221 14.2771 8.05322 14.324 8.05322C14.3381 8.05322 14.3522 6.74934 14.3522 5.15465V2.26077L12.3963 1.13512C11.3223 0.516003 10.4311 0.00476646 10.417 7.62939e-05C10.403 -0.00461388 9.26325 0.637949 7.8937 1.4306Z" fill="#117EC2"/>
                    <path d="M15.7593 8.26443C15.7593 11.1208 15.7733 13.4425 15.7968 13.4331C15.8156 13.4237 16.9412 12.7764 18.3061 11.9885L20.7778 10.5626V8.26443L20.7731 5.96621L18.4937 4.65294C17.2367 3.93064 16.111 3.28339 15.9844 3.20835L15.7593 3.08171V8.26443Z" fill="#DD303A"/>
                    <path d="M1.94645 4.85931L0.0234512 5.97559L0.00938049 8.89761L0 11.8149L0.103185 11.7493C0.164159 11.7118 2.18096 10.5439 4.59175 9.15557C8.53624 6.87612 8.95836 6.62284 8.88332 6.56656C8.6535 6.39771 3.99609 3.73834 3.93511 3.74303C3.89759 3.74303 3.00645 4.24958 1.94645 4.85931Z" fill="#117EC2"/>
                    <path d="M2.4999 11.9836L0 13.4235L0.0140707 15.717L0.0234512 18.0105L2.52335 19.4504L5.01856 20.895V15.717C5.01856 12.87 5.01387 10.539 5.00918 10.539C4.9998 10.5437 3.86945 11.1909 2.4999 11.9836Z" fill="#FC934C"/>
                    <path d="M16.2893 14.7745C13.8317 16.1957 11.8383 17.3729 11.8571 17.3917C11.9368 17.4667 16.7959 20.248 16.8428 20.248C16.8756 20.248 17.7715 19.7462 18.8361 19.1365L20.7732 18.0202L20.7779 15.0982C20.7779 13.4941 20.7732 12.1808 20.7685 12.1855C20.7591 12.1902 18.7423 13.3534 16.2893 14.7745Z" fill="#DD303A"/>
                    <path d="M6.43501 18.8267L6.44908 21.7205L8.42367 22.8603L10.3983 24L12.8935 22.5601C14.263 21.7674 15.3793 21.1014 15.3746 21.0827C15.3605 21.0405 6.51943 15.9328 6.46315 15.9328C6.4397 15.9328 6.43032 17.2367 6.43501 18.8267Z" fill="#FC934C"/>
                </svg>
            </div>
            <template v-if="error">
                <div class="error__title">{{ error.statusCode }}</div>
                <div class="error__text">{{ error.statusMessage }}</div>
            </template>
            <div class="error__button">
                <NuxtLink class="button button--default" :to="'/'">Home</NuxtLink>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .error {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__logo {
            width: 90px;
            height: 100px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__title {
            font-size: 60px;
            font-weight: 700;
            color: var(--text-color);
            margin-bottom: 20px;
        }
        &__text {
            margin-bottom: 20px;
        }
        &__button {
            height: 28px;
        }
    }
</style>

