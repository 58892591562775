import { createStore } from "vuex";
import { common } from '@/store/common'

const store = createStore({
	modules: {
		common: common
	}
});

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(store)
})