import { copyImageToClipboard } from 'copy-image-clipboard'
import domtoimage from 'dom-to-image-more'
import { useNuxtApp } from 'nuxt/app'

export const common = {
    namespaced: true,
    state: () => ({
        notification: {
            text: '',
            isActive: false
        },
        structure: {
            solutions: {
                name: 'Solutions',
                contents: {
                    qpe: {
                        name: 'Quick project evaluations',
                        description: 'With Geonomix, you can conduct a full-cycle evaluation—from geology to economics—in just 3 to 5 days. This will provide you with a solid foundation for decision-making, offering a clear understanding of project value as well as potential risks and pitfalls.',
                    },
                    sfo: {
                        name: 'Screening for opportunities',
                        description: 'Using Geonomix, you can quickly verify the reserves/resources and assess the production potential across multiple development scenarios (incorporating alternative technologies). A clear understanding of the commercial value of the offered license areas can be generated in just a few days (5-7).',
                    },
                    eiont: {
                        name: 'Economic impact of new technologies',
                        description: 'With Geonomix, you can quickly assess the remaining volumes and explore development options using horizontal wells through analytical models or analogs. This will provide a rapid assessment of potential recovery improvements and ways to add economic value to the project.',
                    },
                    dva: {
                        name: 'Data value assessment',
                        description: 'With Geonomix, you can measure the potential added value of the new data, including proper uncertainty ranges. If the added value significantly outweighs the costs, you’ll know you’re on the right track.',
                    },
                    wps: {
                        name: 'Whole-Project sensitivity',
                        description: 'Geonomix allows you to perform sensitivity analysis across the entire project, from geology to economics. The relative influence of any group of parameters—whether porosity, net thickness, well production rates, or commercial assumptions—can be quickly assessed.'
                    },
                    mefs: {
                        name: 'MEFS (Minimum Economic Field Size) definition',
                        description: 'The application of Geonomix enables the prompt calculation of the Minimum Economic Field Size (MEFS), which indicates the volume required to recover all planned investments. The calculated MEFS can then be used to exclude subeconomic volumes from reserve distributions and estimate the probability of Economic Success (Pe). Furthermore, key factors affecting MEFS, such as oil price, well productivity, and net thickness, can be identified and analyzed.'
                    },
                    aou: {
                        name: 'Aggregation of units',
                        description: 'Geonomix provides a sophisticated tool for probabilistic (or arithmetic) summation of individual accumulations or fields, with proper consideration of all possible dependencies between them. This approach may help you identify ways to leverage the potential benefits of portfolio size and diversification for your project.'
                    },
                    prms: {
                        name: 'PRMS categories estimation',
                        description: 'Geonomix provides a fast and reliable means for determining the PRMS categories of petroleum accumulations (e.g., 1P-2P-3P, 1C-2C-3C, or 1U-2U-3U). The probabilistic assessment involves a full-cycle evaluation, including production forecasts for alternative development plans and considerations of commercial uncertainties.'
                    }
                }
            },
            software: {
                name: 'Software',
                contents: {
                    practices: {
                        name: 'Practices',
                        title: 'New software application for integrated value and risk assessment of oil-and-gas projects',
                        description: 'New software application for integrated value and risk assessment of oil-and-gas projects'
                    },
                    advantages: {
                        name: 'Advantages',
                        title: 'Why GEONOMIX?',
                        description: 'Using Geonomix, you can quickly verify the reserves/resources and assess the production potential across multiple development scenarios (incorporating alternative technologies). A clear understanding of the commercial value of the offered license areas can be generated in just a few days (5-7).'
                    },
                    geology: {
                        name: 'Geology',
                        title: 'Geology Probabilistic assessment of reserves and resources',
                        description: 'With Geonomix, you can quickly assess the remaining volumes and explore development options using horizontal wells through analytical models or analogs. This will provide a rapid assessment of potential recovery improvements and ways to add economic value to the project.',
                        slugs: {
                            reserves: {
                                name: 'Reserves and resources'
                            },
                            risk: {
                                name: 'Risk assessment'
                            },
                            libraries: {
                                name: 'Libraries and risk guidelines'
                            },
                            aggregation: {
                                name: 'Aggregation of units'
                            }
                        }
                    },
                    production: {
                        name: 'Production',
                        title: 'Production forecast',
                        description: 'With Geonomix, you can measure the potential added value of the new data, including proper uncertainty ranges. If the added value significantly outweighs the costs, you’ll know you’re on the right track.',
                        slugs: {
                            workflow: {
                                name: 'The workflow'
                            },
                            analysis: {
                                name: 'Probabilistic decline curve analysis (PDCA)'
                            },
                            balance: {
                                name: 'Probabilistic material balance (PMB)'
                            },
                            match: {
                                name: 'Volumetrics-production match'
                            },
                            economics: {
                                name: 'Production forecast. Results to economics'
                            }
                        }
                    }
                }
            },
            services: {
                name: 'Services'
            },
            trainings: {
                name: 'Trainings',
                isDisabled: true
            },
            stories: {
                name: 'Stories',
                isDisabled: true
            }
        },
        showRequestModal: false
    }),
    mutations: {
        setNotification(state, payload) {
            if (payload.isActive == false) {
                state.notification.isActive = payload.isActive
                state.notification.text = ''
            } else {
                state.notification.isActive = payload.isActive
                state.notification.text = payload.text
            }
        },
        setRequestModalStatus(state, payload) {
            state.showRequestModal = payload
        },
    },
    actions: {
        async copyImageToClipboard({ state }, payload) {
            const scale = 2
            const DOMElement = payload

            const isDOMElement = (elem) => {
                try {
                    return elem instanceof HTMLElement
                }
                catch (e) {
                    return (typeof elem === 'object') && (elem.nodeType === 1) && (typeof elem.style === 'object') && (typeof elem.ownerDocument === 'object')
                }
            }

            if (!isDOMElement(DOMElement)) return

            try {
                this.commit('common/setProcess', {
                    status: 'loading',
                    title: 'copy_image.generating'
                })
            
                const dataUrl = await domtoimage.toPng(DOMElement, {
                    width: DOMElement.clientWidth * scale,
                    height: DOMElement.clientHeight * scale,
                    style: {
                        transform: `scale(${scale})`,
                        transformOrigin: 'top left'
                    }
                })
                
                await copyImageToClipboard(dataUrl)
    
                this.commit('common/setProcess', {
                    status: 'display',
                    title: 'copy_image.success'
                })
            } catch (e) {
                this.commit('common/setProcess', {
                    status: 'error',
                    title: 'copy_image.error'
                })
                useNuxtApp().$commonError('failed to copy image to clipboard', e)
            }
        }
    }
}