import { default as aboutT3q1i5PYcfMeta } from "/var/www/www-root/data/www/geonomix.app/pages/about.vue?macro=true";
import { default as contactshqx3B1shmpMeta } from "/var/www/www-root/data/www/geonomix.app/pages/contacts.vue?macro=true";
import { default as _91section_id_93hx3GlDnFq0Meta } from "/var/www/www-root/data/www/geonomix.app/pages/docs/[chapter_id]/[section_id].vue?macro=true";
import { default as index0hdZnmE7onMeta } from "/var/www/www-root/data/www/geonomix.app/pages/docs/[chapter_id]/index.vue?macro=true";
import { default as indexhQbPYoaleeMeta } from "/var/www/www-root/data/www/geonomix.app/pages/docs/index.vue?macro=true";
import { default as index7hVewDp35mMeta } from "/var/www/www-root/data/www/geonomix.app/pages/index.vue?macro=true";
import { default as loginwtuEP02ZiKMeta } from "/var/www/www-root/data/www/geonomix.app/pages/login.vue?macro=true";
import { default as manualsBsiYDNqhT1Meta } from "/var/www/www-root/data/www/geonomix.app/pages/manuals.vue?macro=true";
import { default as policyQs4hnOTFntMeta } from "/var/www/www-root/data/www/geonomix.app/pages/policy.vue?macro=true";
import { default as termsmJF00wQtpkMeta } from "/var/www/www-root/data/www/geonomix.app/pages/terms.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutT3q1i5PYcfMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/about.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: contactshqx3B1shmpMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/contacts.vue")
  },
  {
    name: "docs-chapter_id-section_id",
    path: "/docs/:chapter_id()/:section_id()",
    meta: _91section_id_93hx3GlDnFq0Meta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/docs/[chapter_id]/[section_id].vue")
  },
  {
    name: "docs-chapter_id",
    path: "/docs/:chapter_id()",
    meta: index0hdZnmE7onMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/docs/[chapter_id]/index.vue")
  },
  {
    name: "docs",
    path: "/docs",
    meta: indexhQbPYoaleeMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/docs/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index7hVewDp35mMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginwtuEP02ZiKMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/login.vue")
  },
  {
    name: "manuals",
    path: "/manuals",
    meta: manualsBsiYDNqhT1Meta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/manuals.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: policyQs4hnOTFntMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/policy.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsmJF00wQtpkMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/terms.vue")
  }
]